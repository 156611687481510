<template v-if="pageLoading">
  <div
    class="product-create create-form"
    v-if="getPermission('part:create')"
    style="height: calc(100vh - 140px)"
  >
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-container fluid class="white-background main-create-page">
          <div class="p-5 pb-0">
            <v-row>
              <v-col cols="6" class="pt-0 px-0">
                <h1 class="form-title d-flex font-size-20 align-center">
                  Certificate Details
                  <span class="ml-4">
                    <v-chip
                      label
                      color="chip-custom-blue"
                      outlined
                      class="text-white"
                      style="font-size: 21px !important; font-weight: 700"
                      >{{certificateDetail.barcode}}</v-chip
                    >
                  </span>
                </h1>
              </v-col>
              <v-col cols="6" class="pt-0 text-right">
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  :to="
                  getDefaultRoute('certificate.update', {
                    param: { id: certificateId },
                  })"
                >
                  <v-icon dark left>edit</v-icon> Edit
                </v-btn>
                <v-btn
                  :disabled="!formValid || formLoading"
                  :loading="formLoading"
                  class="mx-2 custom-bold-button text-white"
                  color="cyan"
                  v-on:click="duplicateProduct('duplicate')"
                  ><v-icon small>content_copy</v-icon> Duplicate
                </v-btn>
                <v-btn
                  :disabled="!formValid || formLoading"
                  :loading="formLoading"
                  class="mx-2 custom-bold-button custom-btn-color white--text"
                  v-on:click="dialog = !dialog"
                  ><v-icon small>print</v-icon> Print
                </v-btn>
                <v-btn
                  v-on:click="goBack"
                  :disabled="formLoading"
                  class="custom-grey-border custom-bold-button"
                  style="background-color: #f5f5f5"
                  outlined
                  ><v-icon small>arrow_back</v-icon> Back
                </v-btn>
              </v-col>
              <v-col md="12" class="my-auto py-0">
                <v-row>
                  <v-col md="6"
                    ><v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col md="12">
                            <v-row class="align-center">
                              <v-col md="12" class="py-0">
                                <p
                                  style="font-size: 13px; color: black"
                                  class="pr-2 pl-2 font-weight-800 mb-4"
                                  color="black"
                                >
                                  Certificate Information
                                </p>
                              </v-col>
                              <v-col md="5" class="py-0">
                                <label
                                  for="Serial-No"
                                  class="pr-2 pl-2 font-weight-900 font-size-16 width-100 mb-3"
                                  >Serial No</label
                                >
                              </v-col>
                              <v-col md="5" class="py-0">
                                <p v-if="certificateDetail.serial_no">
                                  <v-chip
                                    label
                                    color="chip-custom-blue"
                                    outlined
                                    small
                                    class="text-white"
                                    style="
                                      font-size: 13px !important;
                                      font-weight: 700;
                                    "
                                    >{{certificateDetail.serial_no}}</v-chip
                                  >
                                </p>
                                <p v-else>No serial no</p>
                              </v-col>
                              <v-col md="5" class="py-0">
                                <label
                                  for="Equipment-Type"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 mb-3"
                                  >Name Of Customer</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18" v-if="certificateDetail.customer_name">
                                  {{certificateDetail.customer_name}}
                                </p>
                                <p v-else>No Customer</p>
                              </v-col>
                              <v-col md="5" class="py-0">
                                <label
                                  for="phone-number"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 mb-3"
                                  >Contact Number</label
                                >
                              </v-col>
                              
                              <v-col md="6" class="py-0">
                                <p  v-if="certificateDetail.contact_no" class="font-weight-600 font-size-18">
                                  {{certificateDetail.contact_no}}
                                </p>
                                <p v-else>No Contact Number</p>
                                
                              </v-col>
                              
                              <v-col md="5" class="py-0">
                                <label
                                  class="pl-2 font-weight-700 font-size-16 width-100 mb-3"
                                  >Quotation Number</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18" v-if="certificateDetail.quotation_no">
                                  {{certificateDetail.quotation_no}}
                                </p>
                                <p v-else>No Quotation</p>
                              </v-col>
                              <v-col md="5" class="py-0">
                                <label
                                  for="address-line-1"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 mb-3"
                                  >Job Number</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18">
                                  {{certificateDetail.job_no}}
                                </p>
                              </v-col>
                              <v-col md="5" class="py-0">
                                <label
                                  for="address-line-1"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 mb-3"
                                  >Equipment ID</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18">
                                  {{certificateDetail.equipment_id}}
                                </p>
                              </v-col>
                              <v-col md="5" class="py-0">
                                <label
                                  for="address-line-1"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 mb-3"
                                  >Equipment Type</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18" v-if="certificateDetail.equipment_type">
                                  {{certificateDetail.equipment_type}}
                                </p>
                                <p v-else> no equipment type </p>
                              </v-col>
                              <v-col md="5" class="py-0">
                                <label
                                  for="address-line-1"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 mb-3"
                                  >Added At</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18" v-if="certificateDetail.added_at">
                                  {{certificateDetail.added_at}}
                                </p>
                                <p v-else>No added at</p>
                              </v-col>
                              <v-col md="5" class="py-0">
                                <label
                                  for="address-line-1"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 mb-3"
                                  >Added By</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18" v-if="certificateDetail.addedBy">
                                  {{certificateDetail.addedBy}}
                                </p>
                                <p v-else>No added by</p>
                              </v-col>

                              <v-col md="5" class="py-0">
                                <label
                                  for="address-line-1"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 mb-3"
                                  >Updated At</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18" v-if="certificateDetail.updated_at">
                                  {{certificateDetail.updated_at}}
                                </p>
                                <p v-else>No updated at</p>
                              </v-col>
                              <v-col md="5" class="py-0">
                                <label
                                  for="address-line-1"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 mb-3"
                                  >Updated By</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18" v-if="certificateDetail.updatedBy">
                                  {{certificateDetail.updatedBy}}
                                </p>
                                <p v-else>No updated by</p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="6">
                    <v-card flat height="417" style="background-color: #e9e9e9">
                      <v-card-text>
                        <v-row>
                          <v-col md="12">
                            <v-row>
                              <v-col md="12" class="py-0">
                                <p
                                  style="font-size: 13px; color: black"
                                  class="pr-2 pl-2 font-weight-800 mb-4"
                                  color="black"
                                >
                                  Schedule Information
                                </p>
                              </v-col>
                              <v-col md="5" class="py-0">
                                <label
                                  for="Equipment-Type"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Calibration Schedule</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18" v-if="certificateDetail.calibration_schedule">
                                  {{certificateDetail.calibration_schedule_formatted}}
                                </p>
                                <p v-else>No Schedule</p>
                              </v-col>
                              <v-col md="5" class="py-0">
                                <label
                                  for="date"
                                  class="pl-2 font-weight-700 font-size-16 width-100"
                                  >Calibration Date</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18">
                                 
                                  {{certificateDetail.formated_calibration_date}}
                                </p>
                              </v-col>
                              <!-- <v-col md="5" class="py-0">
                                <label
                                  for="address-line-1"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >End After</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18">
                                  31/12/2023
                                </p>
                              </v-col> -->
                              <v-col md="5" class="py-0">
                                <label
                                  for="Due-Date"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Due Date</label
                                >
                              </v-col>
                              <v-col md="6" class="py-0">
                                <p class="font-weight-600 font-size-18" v-if="certificateDetail.due_date">
                                  {{certificateDetail.calibration_due_date_formatted}} 
                                  {{certificateDetail.due_date_value?"("+formatDate(certificateDetail.due_date_value)+")":null}}
                                </p>
                                <p v-else>No due date</p>
                              </v-col>
                              <!-- <v-col
                                style="border-top: 1px solid rgb(235, 235, 235)"
                                md="5"
                                class="py-0"
                              >
                                <label
                                  for="Equipment-Type"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 align-center"
                                  >Schedule</label
                                >
                              </v-col> -->
                              <!-- <v-col md="6" class="py-0">
                                <p
                                  v-for="(d_date, idx) in certificate_details"
                                  :key="idx"
                                  class="font-weight-600 font-size-18 align-center"
                                >
                                  <span v-if="idx <= 2"
                                    >{{ d_date.date + ` ` + `(${d_date.day})` }}
                                  </span>
                                </p>
                                <v-chip
                                  v-if="certificate_details.length > 3"
                                  v-on:click="scheduledialog = !scheduledialog"
                                  class="ma-2 text-white my-auto py-0 mt-0"
                                  color="#24326d"
                                  label
                                  small
                                >
                                  <v-icon small>mdi-plus </v-icon>
                                  <span class="me-1">{{
                                    certificate_details.length - 3
                                  }}</span>
                                  More
                                </v-chip>
                              </v-col> -->
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <Dialog :common-dialog="dialog" :dialogWidth="450">
            <template v-slot:title>
              <span style="font-size: 18px; font-weight: 800"
                >Print Certificate Label</span
              >
            </template>
            <template v-slot:body>
              <div class="mx-4">
                <p style="font-weight: 700; font-size: 16px" class="">
                  Are you sure you want to print?
                </p>
              </div>
              <!-- <v-row class="my-auto py-0 mx-4">
                <v-radio-group v-model="row" row>
                  <v-radio color="cyan" label="Yes" value="yex"></v-radio>
                  <v-radio color="cyan" label="No" value="no"></v-radio>
                </v-radio-group>
              </v-row> -->
            </template>
            <template v-slot:action>
              <v-btn
                :loading="formLoading"
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="red"
                v-on:click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
               
                :loading="formLoading"
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="cyan"
                v-on:click="printLabel"
              >
                Print
              </v-btn>
            </template>
          </Dialog>

          <Dialog :common-dialog="scheduledialog" :dialogWidth="500">
            <template v-slot:title>
              <span style="font-size: 20px; font-weight: 550"
                >Certificate Schedule</span
              >
            </template>
            <template v-slot:body>
              <table class="table">
                <thead style="background-color: rgb(206 206 206)">
                  <tr>
                    <th
                      class="simple-table-th"
                      style="background-color: #e9e9e9"
                    >
                      #
                    </th>
                    <th
                      class="simple-table-th"
                      style="background-color: #e9e9e9"
                    >
                      Date
                    </th>
                    <th
                      class="simple-table-th"
                      style="background-color: #e9e9e9"
                    >
                      Day
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="product-listing-row"
                    v-for="(date, index) in certificate_details"
                    :key="index"
                    style="border-bottom: 0.5px solid rgb(236, 235, 235)"
                  >
                    <td
                      style="border-right: 0.5px solid rgb(236, 235, 235);
                        font-weight: normal;
                      "
                      scope="row"
                    >
                      {{ index + 1 }}.
                    </td>
                    <td style="border-right: 0.5px solid rgb(236, 235, 235)">
                      {{ date.date }}
                    </td>
                    <td>{{ date.day }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="scheduledialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductMixin from "@/core/plugins/product-mixin";
import { ProductBrandEventBus } from "@/core/lib/product.brand.lib";
import Dialog from "@/view/pages/partials/Dialog.vue";
import JwtService from "@/core/services/jwt.service";
// import ScheduleList from "@/view/pages/certificate/partials/ScheduleMonthList.vue";
import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";

export default {
  mixins: [CommonMixin, ProductMixin],
  name: "certificate-create",
  title: "Create Certificate",
  data() {
    return {
      certificate_details: [
        {
          t_head: "#",
          date: "01/01/2023",
          day: "Sunday",
        },
        {
          t_head: "DATE",
          date: "01/04/2023",
          day: "Saturday",
        },
        {
          t_head: "DAY",
          date: "01/07/2023",
          day: "Tuesday",
        },
        {
          t_head: "DATE",
          date: "01/04/2023",
          day: "Saturday",
        },
        {
          t_head: "DAY",
          date: "01/07/2023",
          day: "Tuesday",
        },
        {
          schedule_information: [
            {
              calibration_schedule: "3 Month",
              calibration_date: "01/01/2023",
              end_after: "31/12/2023",
              due_date: "1 year after",
            },
          ],
        },
        {
          certificate_information: [
            {
              serial_no: "S-23734545",
              name_of_customer: "Mark Sen",
              contact_number: "+65 38956347",
              quotation_number: "83764967",
              job_number: "345687",
            },
          ],
        },
      ],
      dataLoading: false,
      pageLoading: false,
      row: false,
      scheduledialog: false,
      barcode: null,
      barcodeDialog: false,
      updateMode: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      parttCreate: null,
      certificateCreate: null,
      certificateId: null,
      duplicateId: null,
      dialog: false,
      certificateDetail:{}
    };
  },
  components: {
    // TaggingCreate,
    Dialog,
    // ScheduleList,
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    printLabel(){
      const _this = this;
      const token = JwtService.getToken();
      //const rows = _this.selectedRows;
      const selected = this.certificateId; //rows.join(',');
      const routeData = _this.$router.resolve({name: 'print.label_preview', query: {type: "certificate",token:token,ids:selected}});
      window.open(routeData.href, '_blank');
    },
    duplicateProduct() {
      this.$router.push(
        this.getDefaultRoute("certificate.create", {
          query: {
            duplicate: this.certificateId,
          },
        })
      );
    },
    updateTagging(param) {
      this.certificateCreate = param;
    },
    getCertificate(certificateId) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (certificateId && certificateId > 0) {
          _this.$store
            .dispatch(GET, { url: "certificate/" + certificateId })
            .then((response) => {
              resolve(response.data);
              this.certificateDetail = response.data;
              this.pageLoading=true;
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },

    onSubmit() {
      const _this = this;
      if (!_this.$refs.certificateForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "part";

      if (_this.certificateId && _this.certificateId > 0) {
        requestType = PUT;
        requestURL = "part/" + _this.certificateId;
      }

      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.certificateCreate })
        .then(async (response) => {
          _this.certificateId = response.data ? response.data.id : null;

          _this.$router.push(
            _this.getDefaultRoute("certificate", {
              query: {
                status: "all",
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getCertificate(_this.certificateId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Tagging", route: "certificate" },
              { title: "Update" },
            ]);

            _this.certificateCreate = {
              name: response.name,
              barcode: response.barcode ? response.barcode : null,
              gds_code: response.gds_code ? response.gds_code : null,
              description: response.description ? response.description : null,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,
              unit: response.unit ? response.unit : null,
              initial_stock: response.initial_stock,
              length: response.length,
              weight: response.weight,
              height: response.height,

              length_unit: response.length_unit,
              weight_unit: response.weight_unit,
              height_unit: response.height_unit,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    loadDuplicateContent() {
      const _this = this;
      _this
        .getCertificate(_this.duplicateId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.certificateCreate = {
              name: response.name,
              gds_code: response.gds_code ? response.gds_code : null,
              description: response.description ? response.description : null,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,
              unit: response.unit ? response.unit : null,
              initial_stock: response.initial_stock,
              length: response.length,
              weight: response.weight,
              height: response.height,

              length_unit: response.length_unit,
              weight_unit: response.weight_unit,
              height_unit: response.height_unit,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },

  },

  created() {
    const _this = this;
    _this.certificateId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  mounted() {

    const _this = this;

    if(_this.certificateId){
      this.getCertificate(_this.certificateId);
      this.pageLoading = false;
      //this.contentLoaded = true;
    }

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Certificate", route: "certificate" },
      { title: "Detail" },
    ]);

    if (
      _this.$route.name == "admin.certificate.update" ||
      _this.$route.name == "engineer.certificate.update"
    ) {
      _this.updateMode = true;

      if (_this.certificateId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }

    ProductBrandEventBus.$on("refress-brand", function () {
      this.getCertificate(_this.certificateId);
    });
  },
};
</script>

<style>
.product-listing-row:nth-child(odd) {
  cursor: pointer;
  background-color: #fff4de;
}
.product-listing-row:nth-child(odd) :hover {
  cursor: pointer;
  /* background-color: rgb(245, 245, 245); */
}
</style>
